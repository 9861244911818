import { useTranslation } from "react-i18next";
import React, { useContext } from "react";
import { Container, Navbar, Nav, Stack, NavDropdown } from "react-bootstrap";
import "../../assets/sass/myNav.scss";
import LoginButton from "./LoginButton";
import LoggedIn from "./LoggedIn";
import AuthContext from "../../context/AuthContext";
import LanguageSelector from "./LanguageSelector";
const MyNav = () => {
    const { user, loading } = useContext(AuthContext);
    const { t } = useTranslation([
        "nav",
        "intakeForm",
        "components.mynav",
        "common",
    ]);
    return (
        <Navbar
            collapseOnSelect
            expand="lg"
            bg="dark"
            data-bs-theme="dark"
            id="UniversalTopNavBar"
        >
            <Container>
                <Navbar.Brand
                    href="/"
                    style={{ pointerEvents: loading ? "none" : "auto" }}
                >
                    <img
                        alt=""
                        src={require("./../../assets/img/logo192.png")}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav
                        activeKey={window.location.pathname}
                        className="me-auto"
                    >
                        {/* <Nav.Link href="/" disabled={loading}>{t("home")}</Nav.Link> */}
                        <Nav.Link href="/query-prompt" disabled={loading}>
                            {t("components.mynav:ai_search_tab")}
                        </Nav.Link>
                        <Nav.Link href="/patients" disabled={loading}>
                            {t("components.mynav:patients_tab")}
                        </Nav.Link>
                        <Nav.Link href="/scheduler" disabled={loading}>
                            {t("components.mynav:scheduler_tab")}
                        </Nav.Link>
                        <NavDropdown
                            title={t("components.mynav:forms_tab")}
                            id="Forms-nav-dropdown"
                        >
                            <NavDropdown.Item
                                target="_blank"
                                href="/phone-pre-evaluation"
                                disabled={loading}
                            >
                                {t("intakeForm:ppetitle")}
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                                target="_blank"
                                disabled={true}
                                href="/to-be-determined"
                                // disabled={loading}
                            >
                                IQCODE
                            </NavDropdown.Item>{" "}
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                                target="_blank"
                                disabled={true}
                                href="/to-be-determined"
                                // disabled={loading}
                            >
                                AD8
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                                target="_blank"
                                // disabled={true}
                                href="/initial-evaluation"
                                disabled={loading}
                            >
                                {t("components.mynav:initial_evaluation_form")}
                            </NavDropdown.Item>
                        </NavDropdown>
                        {/* <Nav.Link href="/users-feedbacks" disabled={loading}>Feedbacks</Nav.Link> */}
                    </Nav>
                    <Nav>
                        <Stack
                            direction="horizontal"
                            gap={2}
                            className="justify-content-sm-center justify-content-lg-between"
                        >
                            {user?.token && <LoggedIn />}
                            {!user?.token && (
                                <>
                                    <LoginButton />
                                    <LanguageSelector />
                                </>
                            )}
                        </Stack>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default MyNav;
